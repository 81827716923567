import { showErrorSnackBar } from 'components/errorsnackbar';

import axios, { AxiosError } from 'axios';
import { API_URL, BASENAME } from 'CONSTS';
import { useNavigate } from 'react-router-dom';

/**
 * @param {AxiosError} e Error returned from axios
 * @param {String} title Title for error if response returned, else wise default title will be Error
 * @param {String} msg If no response message, this message will be used instead
 * @param {Boolean} redirectOn401 If true, will redirect on unauthroized
 */
const handleError = (e, title, msg = "Unknown Error", redirectOn401 = true) => {
    if (e.response) {
        if (e.response.data.detail) {
            if (!Array.isArray(e.response.data.detail)) {
                // eslint-disable-next-line no-param-reassign
                msg = e.response.data.detail;
            }
        }

        // Redirect user to sign-in if unauthorized error happens.
        if (e.response.status === 401 && redirectOn401) {
            console.log(e.response)
            if (window.location !== `${BASENAME}/authentication/sign-in`) {
                window.location = `${BASENAME}/authentication/sign-in`
            }
        }
        showErrorSnackBar(title, msg, "error")
    } else {
        showErrorSnackBar("Error!", String(e), "error")
    }
}

/**
 * Get a token back from the api with the given username and password
 * @param {*} username Username of user
 * @param {*} password Password of user
 * @returns A JWT token
 */
const onAuthenticate = (username, password) => {
    return axios(`${API_URL}/login?username=${username}&password=${password}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Sign-In Error!", undefined, false)
            throw e;
        });
};

/**
 * Register the user with the given username, email, and password
 * @param {*} username  Username of user
 * @param {*} email     Email of User
 * @param {*} password  Password of User
 */
const onRegister = (username, email, password) => {
    return axios(`${API_URL}/registerUser?username=${username}&email=${email}&password=${password}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Registration Error!")
            throw e;
        });
}

/**
 * Retrieves a list of all of the necessary user data on the website
 * @returns A list of all users' usernames, emails, isAdmin, and isApproved fields
 */
const retrieveAllUserData = () => {
    return axios(`${API_URL}/retrieveAllUserData`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Data Retrieval Error!")
            console.log(e)
            throw e;
        });
};

/**
 * Returns results for the computers scan data table
 * @param {Number} page 
 * @param {Number} limit 
 * @param {*} sort 
 * @param {*} filter 
 * @returns 
 */
const getComputerTable = (page, limit, sort, filter, controller) => {
    return axios(`${API_URL}/computers`, {
        method: 'GET',
        params: {
            page,
            limit,
            filter,
            sort
        },
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        signal: controller.signal
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error loading scan table!")
            throw e;
        });
}

/**
 * Gets all vulnerabilities associated with a certain computer ID
 * @param {String | Number} computerID Computer ID you want info about
 * @param {Object} filter The filter from datatable
 * @returns 
 */
const getComputerInfo = (computerID, filter) => {
    return axios(`${API_URL}/computers/${computerID}`, {
        method: 'GET',
        params: {
            filter
        },
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error loading computer table!")
            throw e;
        });
}

/**
 * Returns an objct of hotspots in the form {hotspots: [[lat, lng, count]]}
 * @param {*} filter The filter to filter by
 * @returns 
 */
const getHotspotData = (filter) => {
    return axios(`${API_URL}/hotspots`, {
        method: 'GET',
        params: {
            filter
        },
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error loading computer table!")
            throw e;
        });
}

/**
 * @param {String} statisticName The name of the statistic
 * @param {*} filter The filter to filter by
 * @returns 
 */
const getStatistic = (statisticName, filter) => {
    return axios(`${API_URL}/getStatistics`, {
        method: 'GET',
        params: {
            statistic: statisticName,
            filter
        },
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error statistics")
            throw e;
        });
}

const getAvailableJobs = () => {
    return axios(`${API_URL}/jobs`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error statistics")
            throw e;
        });
}

const onResetPW = (email, url) => {
    return axios(`${API_URL}/postResetPW?email=${email}&url=${url}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
    })
    .then(response => response.data)
        .catch(e => {
            handleError(e, "Reset Password Error!")
            throw e;
        });
}

const onPWChange = (password, token) => {
    return axios(`${API_URL}/getResetPW?password=${password}&resetToken=${token}`,{
        method:'GET', 
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
})
    .then(response => response.data)
        .catch(e => {
            handleError(e, "Password Reset Error!")
            throw e;
        });
}

/**
 * Delete the user with the given username
 * @param {*} username  Username of user
 */
const deleteUser = (username) => {
    return axios(`${API_URL}/deleteUser?username=${username}`, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "Error encountered while deleting user!")
            throw e;
        });
}

/**
 * Create the user with the given username, email, password, and isAdmin value
 * @param {*} username  Username of user
 * @param {*} email     Email of User
 * @param {*} password  Password of User
 * @param {*} isAdmin   Indicates whether the user is an admin or not
 */
const onCreateUser = (username, email, password, isAdmin) => {
    return axios(`${API_URL}/createUser?username=${username}&email=${email}&password=${password}&is_admin=${isAdmin}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "User Creation Error!")
            throw e;
        });
}

/**
 * Save the given user changes with the given username, email, password, isAdmin, and isApproved values
 * @param {*} initialUsername   Initial username of User
 * @param {*} username          Username of User
 * @param {*} email             Email of User
 * @param {*} password          Password of User
 * @param {*} isAdmin           Value indicated whether User is an admin or not
 * @param {*} isApproved        Value indicated whether User is approved or not
 */
const onSaveUserChanges = (initialUsername, username, email, password, isAdmin, isApproved) => {
    return axios(`${API_URL}/saveUserChanges?initial_username=${initialUsername}&username=${username}&email=${email}&password=${password}&is_admin=${isAdmin}&is_approved=${isApproved}`, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            handleError(e, "User Save Error!")
            throw e;
        });
}

/**
 * Verify whether the given user is an admin or not
 */
 const isAdmin = (showError) => {
    return axios(`${API_URL}/isAdmin`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    })
        .then(response => response.data)
        .catch(e => {
            if (showError) {
                handleError(e, "Access Denied")
            }
            throw e;
        });
}

export {
    onAuthenticate,
    onRegister,
    getComputerTable,
    getComputerInfo,
    getHotspotData,
    getStatistic,
    getAvailableJobs,
    onResetPW,
    onPWChange,
    retrieveAllUserData,
    deleteUser,
    isAdmin,
    onCreateUser,
    onSaveUserChanges,
}
