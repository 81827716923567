import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet'
import { useEffect, useState } from 'react';
import { getHotspotData } from 'api';
import HeatmapLayer from './HeatmapLayer';
import { useFilterStore } from 'components/ScanDataTable';
import MDBox from "components/MDBox";
import { Card } from '@mui/material';

const MapView = () => {
    const [hotspots, setHotspots] = useState([])

    const filter = useFilterStore(state => state.filter)

    const loadHotspots = () => {
        getHotspotData(filter).then(data => {
            if (data.hotspots && data.hotspots.length > 0) {
                setHotspots(data.hotspots)
            }
        })
    }

    useEffect(() => {
        loadHotspots()
    }, [filter]);

    return (
        <Card>
            <MDBox pt={1} px={2}>
                <MDBox
                    variant="gradient"
                    bgColor="dark"
                    color="dark"
                    borderRadius="xl"
                    mt={-4}
                    style={{height: "400px"}}
                >
                    <MapContainer attributionControl={false} style={{ height: "100%", borderRadius: "10px" }} center={[31.19509, -92.53617]} zoom={7}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a>'
                            url="https://fusion.sdmi.lsu.edu/tiles/styles/dark-matter/{z}/{x}/{y}.png"
                        />
                        <HeatmapLayer dataPoints={hotspots} max={2} radius={30} blur={10} maxZoom={10} />
                        <AttributionControl position="bottomleft" prefix={false} />
                    </MapContainer>
                </MDBox>
            </MDBox>
        </Card >)
}

export default MapView;
