/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Approval from "layouts/authentication/approval";
import UsersTable from "layouts/users"
import AddUser from "layouts/users/add"

// @mui icons
import Icon from "@mui/material/Icon";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Logout from "layouts/authentication/logout";
import { BASENAME } from "CONSTS";
import Dashboard from "layouts/dashboard";
import AuthenticatedView from "components/authentication/AuthenticatedView";
import ResetPassword from "layouts/authentication/reset-password";
import AdminView from "components/authentication/AdminView"
import EditUser from "layouts/users/edit"

const routes = [
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <SupervisedUserCircleIcon fontSize="small">users</SupervisedUserCircleIcon>,
    route: `${BASENAME}/users`,
    component: <AdminView redirect><UsersTable /></AdminView>,
    admin: true
  },
  {
    type: "collapse",
    name: "Sign-Out",
    key: "sign-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: `${BASENAME}/authentication/sign-out`,
    component: <Logout />,
    admin: false
  },
  {
    type: "hidden",
    name: "Reset-Password",
    key: "reset-password",
    icon: <Icon fontSize="small">Reset Password</Icon>,
    route: `${BASENAME}/authentication/reset-password`,
    component: <ResetPassword />,
  },
  {
    type: "hidden",
    name: "Reset-Password",
    key: "reset-password",
    icon: <Icon fontSize="small">Change Password</Icon>,
    route: `${BASENAME}/authentication/reset-password?token=:token`,
    component: <ResetPassword />,
  },
  {
    type: "hidden",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: `${BASENAME}/authentication/sign-in`,
    component: <SignIn />,
    admin: false
  },
  {
    type: "hidden",
    key: "dashboard",
    icon: <Icon fontSize="small">login</Icon>,
    route: `${BASENAME}/`,
    component: <AuthenticatedView redirect><Dashboard /></AuthenticatedView>,
    admin: false
  },
  {
    type: "hidden",
    key: "sign-up",
    icon: <Icon fontsize="small">sign-up</Icon>,
    route: `${BASENAME}/authentication/sign-up`,
    component: <SignUp />,
    admin: false
  },
  {
    type: "hidden",
    key: "approval",
    icon: <Icon fontsize="small">approval</Icon>,
    route: `${BASENAME}/authentication/approval`,
    component: <Approval />,
    admin: false
  },
  {
    type: "hidden",
    key: "edit-user",
    route: `${BASENAME}/users/edit`,
    component: <EditUser />,
    admin: true
  },
  {
    type: "hidden",
    key: "add-user",
    // icon: <Icon fontsize="small">add-user</Icon>
    route: `${BASENAME}/users/add`,
    component: <AddUser />,
    admin: true
  },
];

export default routes;
