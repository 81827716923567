import { useMap } from 'react-leaflet'
import { useEffect, useLayoutEffect, useState } from 'react';
import L from 'leaflet'
import "leaflet.heat"
import PropTypes from "prop-types";


const HeatmapLayer = ({ dataPoints, max, radius, blur, maxZoom }) => {
    const map = useMap()

    const [heatLayer, setHeatLayer] = useState(undefined)


    const cleanUp = () => {
        // remove the old heatmap layer if it exists
        if (heatLayer) {
            map.removeLayer(heatLayer)
        }
    }
    useEffect(() => {

        cleanUp()
        const h = L.heatLayer(dataPoints, { max, radius, blur, maxZoom })

        // keep a ref to this heat layer
        setHeatLayer(h)

        // Add it to the map
        map.addLayer(h)



    }, [dataPoints]);

    useLayoutEffect(() => {
        return () => {
            cleanUp()
        }
    }, [])

    return <></>
}

HeatmapLayer.defaultProps = {
    dataPoints: [],
    max: 1,
    radius: 25,
    blur: 15,
    maxZoom: undefined
};

HeatmapLayer.propTypes = {
    dataPoints: PropTypes.array,
    max: PropTypes.number,
    radius: PropTypes.number,
    blur: PropTypes.number,
    maxZoom: PropTypes.oneOf(PropTypes.any, PropTypes.number)
};

export default HeatmapLayer