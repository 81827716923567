import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material"
import { getStatistic } from "api"
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard"
import PropTypes from "prop-types";

function APIStatisticCard({ statName, filter, title }) {
    const [stat, setStat] = useState(0)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        getStatistic(statName, filter).then((data) => {
            if (data[statName]) {
                setStat(data[statName])
            }
        }).finally(() => {
            setLoading(false)
        })

    }, [filter, statName])

    return <ComplexStatisticsCard
        color="secondary"
        icon="error"
        title={title}
        count={stat}
        loading={loading}
    />

}

APIStatisticCard.propTypes = {
    statName: PropTypes.string.isRequired,
    filter: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
}

const Statistics = () => {

    return (
        <Grid container spacing={3} >
            <Grid item xs={12} sm={6} lg={3}>
                <APIStatisticCard statName="new_computers" filter={7} title="Devices Added in Past 7 Days" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <APIStatisticCard statName="active_computers" filter={7} title="Devices Vulnerable in Past 7 Days" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <APIStatisticCard statName="active_computers" filter={1} title="Devices Vulnerable in Past 24/hrs" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <APIStatisticCard statName="computers_with_score_gt" filter={80} title="Devices With Score > 80" />
            </Grid>

        </Grid>
    )
}

export default Statistics