/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { BASENAME } from "CONSTS";
import { showErrorSnackBar } from "components/errorsnackbar";
import { onSaveUserChanges } from "../../../api"

function isStringTrue(string) {
  return string === 'True' || string === 'true'
}

function Cover() {

  const { state } = useLocation();

  const { initialUsername, initialEmail, initialIsAdmin, initialIsApproved } = state;
  const [username, setUsername] = useState(initialUsername)
  const [email, setEmail] = useState(initialEmail)
  const [password, setPassword] = useState("")
  const [verifyPassword, setVerifyPassword] = useState("")
  const [isAdmin, setIsAdmin] = useState(isStringTrue(initialIsAdmin))
  const [isApproved, setIsApproved] = useState(isStringTrue(initialIsApproved))


  const navigate = useNavigate();
  const [loadingRegister, setLoadingRegister] = useState(false)

  function changesMade() {
    let wereChangesMade = false;

    if (username !== initialUsername || email !== initialEmail || password !== "" ||
        isAdmin !== isStringTrue(initialIsAdmin) || isApproved !== isStringTrue(initialIsApproved)) {
          wereChangesMade = true;
        }

    return wereChangesMade;

  }

  const handleSaveChanges = (form) => {
    /* eslint-disable no-unused-expressions */

    form.preventDefault()
    let errors = ""

    // Make sure passwords match
    if (password !== verifyPassword) {
      errors += "Password and verify passwords do not match!\n"
    }

    // Make sure no fields are left blank
    !username && (errors += "Username required!\n")
    if (password || verifyPassword) {
      !password && (errors += "Password required!\n")
      !verifyPassword && (errors += "Verify Password required!\n")
    }
    !email && (errors += "Email required!\n")

    if (errors !== "") {
      showErrorSnackBar("Error", errors, "error")
      return 0
    }

    setLoadingRegister(true)

    // Checking to see if the user made any changes
    if (changesMade()) {
      onSaveUserChanges(initialUsername, username, email, password, isAdmin, isApproved)
        .then(() => {
          // Navigate back to users table page
          navigate(`${BASENAME}/users`)
        }).catch(() => {
          setLoadingRegister(false)
        })
    } else {
      /**
       * Navigate back to users table page because no changes were made (no need
       * to make an API call)
       */
      navigate(`${BASENAME}/users`)
    }

    return 0
  }

  function handleCancel() {
    navigate(`${BASENAME}/users`)
  }

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Editing User: {initialUsername}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Please edit the user&apos;s details below
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="Username" value={username} variant="standard" fullWidth onChange={(e) => setUsername(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" value={email} variant="standard" fullWidth onChange={(e) => setEmail(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="New Password" value={password} variant="standard" fullWidth onChange={(e) => setPassword(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Verify Password" value={verifyPassword} variant="standard" fullWidth onChange={(e) => setVerifyPassword(e.target.value)} />
            </MDBox>
            <MDBox display="flex" alignItems="center" justifyContent="flex-end" ml={-1}>
              <Checkbox defaultChecked={isStringTrue(initialIsAdmin)} onChange={(e) => setIsAdmin(e.target.checked)} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1, mr: "auto" }}
              >
                &nbsp;&nbsp;Admin
              </MDTypography>
              <Checkbox defaultChecked={isStringTrue(initialIsApproved)} onChange={(e) => setIsApproved(e.target.checked)} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Is Approved
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton loading={loadingRegister} variant="gradient" color="info" fullWidth onClick={handleSaveChanges}>
                Save Changes
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="secondary" fullWidth onClick={handleCancel}>
                cancel
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
