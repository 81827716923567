/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { BASENAME, API_URL } from "CONSTS";
import { useEffect, useState } from "react";
import { onResetPW, onPWChange } from '../../../api';
import { showErrorSnackBar } from "components/errorsnackbar";



function ResetPassword() {

  const [newPassword, setPassword] = useState("")
  const [verifyPassword, setVerify] = useState("")
  const [email, setEmail] = useState("")
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search)
  const resetToken = queryParams.get("token")
  const [loading, setLoading] = useState(false)

  const handleChangePW = (form) => {
    form.preventDefault()
    let errors = ""

    // Make sure passwords match
    if (newPassword !== verifyPassword) {
      errors += "Password and verify passwords do not match!\n"
    }

    // Make sure all fields have inputs
    if (!newPassword) { errors += "Password required!\n" }
    if (!verifyPassword) { errors += "Verify Password required!\n" }

    if (errors !== "") {
      showErrorSnackBar("Error", errors, "error")
      return 0
    }

    setLoading(true)
    onPWChange(newPassword, resetToken).then(()  => {
      navigate(`${BASENAME}/authentication/sign-in`)
    })
    .catch((error) => {
      console.log(error)
      showErrorSnackBar("Error", error)
      setLoading(false)
    })
    return 0
  }

  const handleEmailSend = (form) => {
    form.preventDefault()

    setLoading(true)
    onResetPW(email, window.location.origin).then((data) => {
      // Navigate back to login screen 
      navigate(`${BASENAME}/authentication/sign-in`)
    }).catch((e) => {
      console.log(e)
      showErrorSnackBar("Error", e)
      setLoading(false)
    })
  }
  if (resetToken !== null) {
    return (
      <CoverLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Please set your new Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput type="password" label="New Password" variant="standard" fullWidth value={newPassword} onChange={(e) => setPassword(e.target.value)} />
              </MDBox>
              <MDBox mb={4}>
                <MDInput type="password" label="Verify Password" variant="standard" fullWidth value={verifyPassword} onChange={(e) => setVerify(e.target.value)} />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton loading={loading} variant="gradient" color="info" fullWidth type="submit" onClick={handleChangePW}>
                  reset
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    );
  }
  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail within 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput type="email" label="Email" variant="standard" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton loading={loading} variant="gradient" color="info" fullWidth type="submit" onClick={handleEmailSend}>
                reset
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Return to log-in?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}
export default ResetPassword;

