/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

function Cover() {

  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Thank you for registering an account!
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
            <MDBox mt={1} mb={1} textAlign="center">
            <MDTypography display="block" variant="button" color="white" my={1}>
            An admin must review and approve your account before you are granted access to Fusion Dash.
          </MDTypography>
              <MDTypography variant="button" color="text">
                Back to{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login Page
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;