import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isAdmin } from "../../../api"

function AdminView({ children, redirect }) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)

    /**
     * Function to check whether a user is an admin or not.
     */
    const isUserAdmin = async () => {
        try {
            await isAdmin(true)
        }
        catch (e) {
            if (redirect) {
                navigate(-1)
            }
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        isUserAdmin()
    }, []);

    if (loading) {
        return <>Loading...</>
    }

    return children
}

AdminView.defaultProps = {
    redirect: false
}

AdminView.propTypes = {
    children: PropTypes.node.isRequired,
    redirect: PropTypes.bool,
}


export default AdminView;