/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { BASENAME } from "CONSTS";
import { onRegister } from '../../../api';
import { showErrorSnackBar } from "components/errorsnackbar";

function Cover() {

  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [verifyPassword, setVerifyPassword] = useState("")


  const navigate = useNavigate();
  const [loadingRegister, setLoadingRegister] = useState(false)

  const handleRegister = (form) => {
    /* eslint-disable no-unused-expressions */

    form.preventDefault()
    let errors = ""

    // Make sure passwords match
    if (password !== verifyPassword) {
      errors += "Password and verify passwords do not match!\n"
    }

    // Make sure no fields are left blank
    !username && (errors += "Username required!\n")
    !password && (errors += "Password required!\n")
    !verifyPassword && (errors += "Verify Password required!\n")
    !email && (errors += "Email required!\n")

    if (errors !== "") {
      showErrorSnackBar("Error", errors, "error")
      return 0
    }

    setLoadingRegister(true)
    onRegister(username, email, password)
      .then(() => {
        // Navigate to approval page
        navigate(`${BASENAME}/authentication/approval`)
      }).catch(() => {
        setLoadingRegister(false)
      })

    return 0
  }

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="Username" value={username} variant="standard" fullWidth onChange={(e) => setUsername(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" value={email} variant="standard" fullWidth onChange={(e) => setEmail(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" value={password} variant="standard" fullWidth onChange={(e) => setPassword(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Verify Password" value={verifyPassword} variant="standard" fullWidth onChange={(e) => setVerifyPassword(e.target.value)} />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton loading={loadingRegister} variant="gradient" color="info" fullWidth onClick={handleRegister}>
                register
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
