import { CircularProgress, TableCell, TableHead, TableRow, ThemeProvider, Button, ButtonGroup, Table, } from "@mui/material";
import MUIDataTable, { debounceSearchRender, TableBody } from "mui-datatables";

import { useEffect, useState } from "react";
import { getComputerTable, getComputerInfo } from "api";
import MDBox from "components/MDBox";
import { getDarkTheme, getLightTheme } from "./tableTheme";
import { useMaterialUIController } from "context";
import create from 'zustand'
import MultiFilterInput from "components/MUIDataTableExtensions/MultiFilterInput";

const ip = require('ip');

export const useFilterStore = create(set => ({
  filter: {},
  setFilter: (filter) => set(state => ({ filter })),
}))

/**
 * Helps map filter args to react js component
 * @param  {...any} args 
 * @returns 
 */
const multiFilterMapper = (...args) => {

  const filterList = args[0]
  const onChange = args[1]
  const index = args[2]
  const column = args[3]
  const filterData = args[4]
  return <MultiFilterInput filterList={filterList} onChange={onChange} index={index} column={column} filterData={filterData} type="text" />
}

const chipText = (value, name) => {
  return value.map((v) => {
    return `${name}: ${v}`;
  })
}

function ExpandedRow({ row }) {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [cveData, setCVEData] = useState({})
  const ipStr = row[0]

  useEffect(() => {
    getComputerInfo(ip.toLong(ipStr)).then(d => {
      setData(d)
      console.log(d)
    }).catch(e => {
      console.log(e)
    })
  }, [])

  // Displaying the port number and the dates it was observed for the given port
  function addPortRow(port) {
    // Converting the port's "Date Observed" array to a comma separated string
    const dates = port[1].map(item => `${item}, `);
    
    // Removing the last ", " in the string
    const lastIndex = dates.length - 1;
    const lastDate = dates[lastIndex];
    dates[lastIndex] = lastDate.substring(0, lastDate.length - 2);

    return (
      <TableRow>
        <TableCell className="portName" >
          {port[0]}
        </TableCell>
        <TableCell className="datesObserved" >
          {dates}
        </TableCell>
      </TableRow>
    );
  }

  // Loading the port table once a computer row is clicked
  const getPortTable = () => {
    // If the computer data and port_history has loaded, start rendering the port data
    if (data !== undefined && data.port_history !== undefined) {
      // Pushing all of the port_history data for the selected computer into a dict
      const portDict = {}
      data.port_history.forEach(port => {
        if (portDict[`${port.port}`] !== undefined) {
          portDict[`${port.port}`].push(port.date_observed)
        } else {
          portDict[`${port.port}`] = [port.date_observed]
        }
      });

      if(data.port_history.length === 0) {
        return "No Port History"
      }

      return (
        <Table sx={{ ml: "2vw" }} >
          <TableHead>
            <TableRow>
              <TableCell>Port Number</TableCell>
              <TableCell align="right">Dates Observed</TableCell>
            </TableRow>
          </TableHead>
          {Object.entries(portDict).map(addPortRow)}
        </Table>
      )
    }

    // If the selected computer data is still being gathered, just show "Loading..."
    return <CircularProgress>Loading...</CircularProgress>
  }

  const getCVETable = () => {
    if (data !== undefined && data.cve_history !== undefined) {
      const cveDict = {}
      data.cve_history.forEach(cve => {
        if (cveDict[`${cve.cve_name}`] !== undefined) {
          cveDict[`${cve.cve_name}`].push(cve.date_observed)
        } else {
          cveDict[`${cve.cve_name}`] = [cve.date_observed]
        }
      });

      if(data.cve_history.length === 0){
        return "No CVE history"
      }

      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CVE Name</TableCell>
              <TableCell align="right">Dates Observed</TableCell>
            </TableRow>
          </TableHead>
          {Object.entries(cveDict).map(e => <TableRow><TableCell>{e[0]}</TableCell><TableCell>{String(e[1])}</TableCell></TableRow>)}
        </Table>
      )
    }


    return <CircularProgress>Loading...</CircularProgress>
  }

  return (
    <TableRow >
      <TableCell colSpan={row.length + 1} style={{ "padding": 10 }}>
        <div>
          {getCVETable()}
        </div>

        <br />

        <div>
          {getPortTable()}
        </div>

        <br />

        <ButtonGroup variant="contained" size="xs">
          <Button target="_blank" href={`https://www.virustotal.com/gui/ip-address/${ipStr}`}>VirusTotal </Button>
          <Button target="_blank" href={`https://www.shodan.io/host/${ipStr}`}>Shodan </Button>
          <Button target="_blank" href={`https://www.talosintelligence.com/reputation_center/lookup?search=${ipStr}`}>Talos </Button>
          <Button target="_blank" href={`https://www.abuseipdb.com/check/${ipStr}`}>AbuseDB </Button>
        </ButtonGroup>
        {/* TODO: Use MUI Menu to do an "OPEN IN" type box */}
      </TableCell>
    </TableRow>
  )
}

/* eslint react/prop-types: 0 no-param-reassign: 0 */
export default function ScanDataTable() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(15)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState({})

  const filter = useFilterStore(state => state.filter)
  const setFilter = useFilterStore(state => state.setFilter)

  let abortController = new AbortController();

  useEffect(() => {
    // Cancel old requests
    abortController.abort()
    // Create new cancel controller
    abortController = new AbortController();

    setLoading(true)

    getComputerTable(page, limit, sort, filter, abortController)
      .then((e) => {
        setData(e.computers)
        console.log(e.computers)
        setCount(e.total)
      }).finally(() => {
        setLoading(false)
      })

  }, [page, limit, sort, filter])

  const handleFilter = (tableState) => {
    const combined = {}
    // Combine columns and filters
    tableState.columns.forEach((column, i) => {
      combined[column.name] = tableState.filterList[i]
    })

    setFilter(combined)
  }

  const options = {
    search: false,
    serverSide: true,
    count,
    page,
    customSearchRender: debounceSearchRender(5000),
    expandableRows: true,
    expandableRowsOnClick: true,
    expandableRowsHeader: false,
    rowsPerPage: limit,
    rowsPerPageOptions: [10, 15, 20, 30, 50, 100],
    selectableRowsHideCheckboxes: true,
    confirmFilters: true,
    storageKey: "computer_table",
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button variant="contained" onClick={applyNewFilters}>Apply Filters</Button>
        </div>
      );
    },
    renderExpandableRow: (row, rowMeta) => { return <ExpandedRow row={row} /> },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page)
          break;
        case 'sort':
          setSort(tableState.sortOrder)
          break;
        case 'changeRowsPerPage':
          setLimit(tableState.rowsPerPage)
          break;
        case 'search':
          break;
        case 'resetFilters':
        case 'filterChange':
          handleFilter(tableState)
          break;
        case 'propsUpdate':
          break;
        case 'onSearchOpen':
          break;
        default:
          // eslint-disable-next-line
          console.log('action not handled.', action, tableState);
      }
    }
  };


  const columns = [
    {
      name: "ip_str",
      label: "Host",
      options: {
        filter: true,
        sort: true,
        filterType: "custom",
        customFilterListOptions: { render: e => chipText(e, "Host") },
        filterOptions: { display: multiFilterMapper }
      }
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
        filterType: "custom",
        customFilterListOptions: { render: e => chipText(e, "State") },
        filterOptions: { display: multiFilterMapper }
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: true,
        filterType: "custom",
        customFilterListOptions: { render: e => chipText(e, "City") },
        filterOptions: { display: multiFilterMapper }
      }
    },
    {
      name: "isp",
      label: "ISP",
      options: {
        filter: true,
        sort: true,
        filterType: "custom",
        customFilterListOptions: { render: e => chipText(e, "ISP") },
        filterOptions: { display: multiFilterMapper }
      }
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: true,
        filterType: "custom",
        display: true,
        customFilterListOptions: { render: e => chipText(e, "Score") },
        filterOptions: { display: multiFilterMapper },
        customBodyRender: (value, tableMeta, updateValue) => {return value}
      }
    },
    {
      name: "cve",
      label: "CVE Name",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        display: false,
        customFilterListOptions: { render: e => chipText(e, "CVE") },
        filterOptions: { display: multiFilterMapper }
      }
    },
    {
      name: "port",
      label: "Port",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        display: false,
        customFilterListOptions: { render: e => chipText(e, "Port") },
        filterOptions: { display: multiFilterMapper }
      },
    },
  ];

  /**
   * Shows during any loading of the table.
   * @param {*} props 
   * @returns 
   */
  const loadingTableBody = (props) => {
    return <TableHead>
      <TableRow>
        <TableCell colSpan={props.columns.length}>
          <MDBox m={5} sx={{ textAlign: "center" }}>
            <CircularProgress title="Loading..." />
          </MDBox>
        </TableCell>
      </TableRow>
    </TableHead>
  }

  return (
    <ThemeProvider theme={darkMode ? getDarkTheme() : getLightTheme()}>
      <MUIDataTable
        title="Computers"
        columns={columns}
        options={{
          ...options
        }}
        data={data}
        components={{
          TableBody: loading ? loadingTableBody : null
        }}
      />
    </ThemeProvider>
  )
}

