/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// Dashboard components
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Fade, Paper, Tooltip, Typography } from "@mui/material";
import ScanDataTable from "components/ScanDataTable";
import MapView from "components/MapView";
import Statistics from "./components/Satistics";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import Jobs from "components/Jobs";

const FADE_TIME = 1000

function Dashboard() {
  const { scanID } = useParams();

  useEffect(() => {
  }, [scanID])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Fade in timeout={FADE_TIME}>
        <MDBox>
          {/* <Jobs /> */}
          <Statistics />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6} mt={4.5}>
              <MapView />
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={4.5}>
              <div style={{ height: "100%" }}>
                <ReportsLineChart
                  chart={{ datasets: { label: "", data: [50, 40, 300, 320, 500] }, labels: ["1", "2", "3", "4", "5"] }}
                  color="primary"
                  title="Daily New Computers"
                  description="Computers added per day"
                />
              </div>
            </Grid>
          </Grid>

          <MDBox mt={4.5} mb={4.5}>
            <ScanDataTable />
          </MDBox>
        </MDBox>
      </Fade>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
