import { Chip, FormControl, Icon, IconButton, TextField, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { isNumeric } from "helpers";
import { IndeterminateCheckBoxOutlined } from "@mui/icons-material";
/* eslint react/prop-types:0 */
export default function MultiFilterInput({ filterList, onChange, index, column, filterData, type }) {
    const [value, setValue] = useState("")
    const [error, setError] = useState("")

    const addValue = () => {
        setValue("")
    }

    const handleChange = (e) => {
        const val = e.target.value
        setValue(val)

        /* eslint-disable no-param-reassign */
        filterList[index] = filterList[index].filter((item) => {
            return item !== value
        })

        if (val === "") { return }

        filterList[index].push(val)
        onChange(filterList[index], index, column)
    }


    return (<FormControl>
        <Stack direction="row" spacing={1} sx={{ overflowX: "auto", overflowY: 'auto' }}>
            {filterList[index].map((e) => {
                if (e !== value) {
                    return <Chip onDelete={() => {
                        /* eslint-disable no-param-reassign */
                        filterList[index] = filterList[index].filter((item) => {
                            return item !== e
                        })
                        
                        onChange(filterList[index], index, column)

                    }} key={e} color="error" label={e} />
                }
                return null
            })}

        </Stack>
        <TextField
            aria-describedby="component-error-text"
            margin="none"
            error={error}
            helperText={error}
            variant="standard"
            label={column.label}
            InputProps={{
                endAdornment: (<IconButton
                    onClick={addValue}>
                    <Icon>
                        add
                    </Icon>
                </IconButton>)
            }}
            value={value}
            onChange={e => handleChange(e)} />
    </FormControl>)
}
/*
MultiFilterInput.propTypes = {
    filterList: PropTypes.array,
    onChange: PropTypes.func,
    index: PropTypes.int,
} */
