/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { retrieveAllUserData, deleteUser } from "../../api";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CustomNavbar from "layouts/navbars/CustomNavbar";
import Footer from "components/Footer";
import DataTable from "examples/Tables/DataTable";
import { CircularProgress, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
// Data
import { useEffect, useState } from "react";

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles'
import { BASENAME } from "CONSTS";
import { useNavigate } from 'react-router-dom';

function Tables() {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const theme = useTheme();

  const columns = [
    { Header: "username", accessor: "username", width: "45%", align: "left" },
    { Header: "email", accessor: "email", align: "left" },
    { Header: "admin", accessor: "is_admin", align: "center" },
    { Header: "approved", accessor: "is_approved", align: "center" },
    { Header: "", accessor: "delete_user", align: "center" },
  ]

  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("")
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [loadingAddUser, setLoadingAddUser] = useState(false)


  const handleClose = () => {
    setOpen(false);
  };

  function handleEdit(username, email, isAdmin, isApproved) {
    navigate(`${BASENAME}/users/edit`, {
      state: {
        initialUsername: username,
        initialEmail: email,
        initialIsAdmin: isAdmin,
        initialIsApproved: isApproved
      }
    })
  }

  function handleOpen(username) {
    setSelectedUser(username)
    setOpen(true);
  }

  function handleAddUser() {
    setLoadingAddUser(true)
    try {
      navigate(`${BASENAME}/users/add`)
    } catch (error) {
      setLoadingAddUser(false)
    }
  }

  function createIcons(username, email, isAdmin, isApproved) {
    const id = `#${username}`
    return (
      <div>
        <IconButton id={id} onClick={() => handleOpen(username)}>
          <DeleteIcon color="light" />
        </IconButton>
        <IconButton id={id} onClick={() => handleEdit(username, email, isAdmin, isApproved)}>
          <EditIcon color="light" />
        </IconButton>
      </div>
    )
  }

  function addDeleteButton(row) {
    const newRow = row
    const deleteButton = createIcons(newRow.username, newRow.email, newRow.is_admin, newRow.is_approved)
    newRow.delete_user = deleteButton

    return newRow
  }

  function loadPage() {
    setLoading(true)
    retrieveAllUserData().then((data) => {
      setRows(data.map(addDeleteButton))
    }).finally(() => {
      setLoading(false)
    })
  }

  function handleDelete() {
    deleteUser(selectedUser).then(() => {
      handleClose()
      loadPage()
    })
  }

  useEffect(() => {
    loadPage()
  }, [])

  /**
   * Shows during any loading of the table.
   * @param {*} props 
   * @returns 
   */
  const loadingTableBody = () => {
    return <TableHead>
      <TableRow>
        <TableCell colSpan={4}>
          <MDBox m={5} sx={{ textAlign: "center" }}>
            <CircularProgress title="Loading..." />
          </MDBox>
        </TableCell>
      </TableRow>
    </TableHead>
  }

  return (
    <DashboardLayout>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you would like to delete user &quot;{selectedUser}&quot;?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <CustomNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                <MDTypography variant="h6" color="white" mr="auto" >
                  Users Table
                </MDTypography>
                <MDButton variant="gradient" color="light" fontColor="dark" onClick={handleAddUser} >
                  <PersonAddIcon />
                  &nbsp;&nbsp;Add User
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  components={{
                    TableBody: loading ? loadingTableBody : null
                    // TODO: this component isn't being loaded, possibly because
                    // we're working with a DataTable and not a MUIDataTable
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
