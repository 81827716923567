import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URL, BASENAME } from 'CONSTS';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function AuthenticatedView({ children, redirect }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)

    /**
     * Function to validate whether a token is valid or not.
     */
    const validateToken = async (source) => {

        try {
            // TODO: store this in a store and dont fetch it if its been X amount of minutes.
            const token = localStorage.getItem("token")

            const config = {
                headers: { Authorization: `Bearer ${token}` },
                cancelToken: source.token
            };

            await axios.get(`${API_URL}/validateToken`, config)
        }
        catch (e) {
            console.log("Failed to validate token")
            if (redirect) {
                navigate(`${BASENAME}/authentication/sign-in`)
            }
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        const source = axios.CancelToken.source();
        validateToken(source)
        return () => {
            // Cancel axios request if unmounted
            source.cancel("Cancelling in cleanup");
        };
    }, []);

    if (loading) {
        return <>Loading...</>
    }

    return children
}

AuthenticatedView.defaultProps = {
    redirect: false
}

AuthenticatedView.propTypes = {
    children: PropTypes.node.isRequired,
    redirect: PropTypes.bool
}


export default AuthenticatedView;